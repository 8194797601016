var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"footer-tag":"footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-button-loading',{attrs:{"text":"AGREGAR","type":"submit","processing":_vm.processing}})]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"NOMBRE DEL SERVICIO","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"nombre del ccc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Ingrese nombre del servicio ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"NOTA MÍNIMA APROBATORIA","label-for":"minimum_score"}},[_c('validation-provider',{attrs:{"rules":"required|positive|between:1,20","name":"nota mínima aprobatoria"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minimum_score","type":"number","placeholder":"0","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.minimum_score),callback:function ($$v) {_vm.$set(_vm.formData, "minimum_score", $$v)},expression:"formData.minimum_score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"MONTO EN SOLES","label-for":"amount"}},[_c('validation-provider',{attrs:{"rules":"required|positive","name":"monto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount","placeholder":"0.00","type":"number","step":".01","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.amount),callback:function ($$v) {_vm.$set(_vm.formData, "amount", $$v)},expression:"formData.amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"CANTIDAD MÁXIMA DE INSCRIPCIONES","label-for":"max_inscriptions"}},[_c('b-form-input',{attrs:{"id":"max_inscriptions","placeholder":"0","type":"number","trim":""},model:{value:(_vm.formData.max_inscriptions),callback:function ($$v) {_vm.$set(_vm.formData, "max_inscriptions", $$v)},expression:"formData.max_inscriptions"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"TIPO DE SERVICIO","label-for":"course_type"}},[_c('validation-provider',{attrs:{"rules":"required","name":"tipo de servicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"course_type","placeholder":"Seleccionar una opción","options":_vm.options.courseTypes,"state":errors.length > 0 ? false : null},on:{"input":_vm.onCourseTypeChange},model:{value:(_vm.formData.course_type),callback:function ($$v) {_vm.$set(_vm.formData, "course_type", $$v)},expression:"formData.course_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.formData.course_type && _vm.formData.course_type.value === 1)?[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"LINK DE LA CLASE","label-for":"class_link"}},[_c('validation-provider',{attrs:{"rules":"required|url","name":"link de la clase"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"class_link","placeholder":"Ingrese el link de la clase (Zoom, Google Meet, TEAMS, etc.) ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.class_link),callback:function ($$v) {_vm.$set(_vm.formData, "class_link", $$v)},expression:"formData.class_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2449905662)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"FECHA Y HORA DE LA CLASE","label-for":"class_datetime"}},[_c('validation-provider',{attrs:{"rules":"required","name":"fecha y hora de la clase"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"class_datetime","config":_vm.config,"placeholder":"YYYY-MM-DD HH:mm","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.class_datetime),callback:function ($$v) {_vm.$set(_vm.formData, "class_datetime", $$v)},expression:"formData.class_datetime"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.clearDate('class_datetime')}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"12"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2901079424)})],1)],1)]:_vm._e(),(_vm.formData.course_type && _vm.formData.course_type.value === 2)?[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"VIDEO DE LA CLASE","label-for":"class_video"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Video de la clase"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"class_video","accept":"video/*","state":Boolean(_vm.videoFile)? false : null,"placeholder":"Agregar el video del curso...","drop-placeholder":"Arrastra tu video aquí..."},model:{value:(_vm.videoFile),callback:function ($$v) {_vm.videoFile=$$v},expression:"videoFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1894206057)})],1)],1),(Boolean(_vm.videoFile))?_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"align-h":"center","align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-card',[_c('b-aspect',{attrs:{"aspect":"16:9"}},[_c('video',{ref:"videoDom",staticStyle:{"max-width":"100%","width":"fit-content","max-height":"600px","margin":"auto"},attrs:{"controls":""}})])],1)],1)],1)],1):_vm._e()]:_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"DESCRIPCIÓN","label-for":"description"}},[_c('validation-provider',{attrs:{"rules":"required","name":"descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticStyle:{"min-height":"120px"},attrs:{"id":"description","options":_vm.snowOption,"state":(errors.length > 0 ? false:null),"trim":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],2)],1)],1)],1)],1),_c('b-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaderInfo.isLoadingInfo),expression:"loaderInfo.isLoadingInfo"}],ref:"loaderModalDom",attrs:{"title":"Enviando Información","hide-footer":false,"hide-header-close":"","no-close-on-backdrop":"","no-close-on-esc":"","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('p',{staticClass:"float-left"}),_c('b-button-loading',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm","text":"Cancelar","type":"button","processing":false},on:{"process-action":_vm.onCancelLoader}})],1)]},proxy:true}])},[_c('b-row',{staticClass:"d-block px-1"},[_c('b-alert',{attrs:{"variant":"info","show":""}},[_c('b-icon',{attrs:{"icon":"exclamation-circle-fill","variant":"info"}}),_vm._v(" No refrescar la página ")],1)],1),_c('div',{staticClass:"d-block text-center"},_vm._l((_vm.loaderInfo.steps),function(loader,index){return _c('div',{key:("title-" + index),staticClass:"mb-2"},[_c('p',{staticClass:"mb-0 text-left pl-1"},[_vm._v(" "+_vm._s(_vm.defineLoaderLabel(loader))+" ")]),_c('b-progress',{key:("loader-" + index),attrs:{"max":100,"height":"2rem","show-progress":"","animated":loader.state !== _vm.videoLoaderState.FINISHED}},[_c('b-progress-bar',{attrs:{"value":loader.progress}},[_c('div',{staticClass:"text-overflow-ellipsis",staticStyle:{"width":"90%","height":"100%","line-height":"2rem","margin":"auto"}},[_c('span',[_vm._v("Avance: "),_c('strong',[_vm._v(_vm._s(loader.progress)+" / "+_vm._s(100))])])])])],1)],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }